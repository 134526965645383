import axios from "axios";
import { endpoint, prodEndpoint } from "../config";
// import { createHashHistory } from "history"; // or createBrowserHistory
// const history = createHashHistory();

var url = process.env.NODE_ENV === "development" ? endpoint : prodEndpoint;

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(function (config) {
  const bearerToken = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  config.headers.Authorization = bearerToken;

  return config;
});

// instance.interceptors.request.use(
//   function (config) {
//     // const userToken = getToken();
//     // config.headers.Authorization = "Bearer " + userToken;
//     //config.headers.Accept = "application/json";
//     // alert("passou");
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

instance.interceptors.response.use(
  function (response) {
    if (response.data.code == 500) {
      //excessão tratada no servidor
      return undefined; // podemos redirecionar para uma tela de ocorreu um erro e tentar nomanete, cmo na funciton error abaixo
    }

    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      window.location =
        "/login?urlReturn=" +
        window.location.pathname +
        (window.location.search || "");
    } else if (500 === error.response.status) {
      // mostrar tela de um erro inesperado na tela
    } else if (403 === error.response.status) {
      window.location = "/acessonegado";
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
