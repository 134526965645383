import React, { useContext, useEffect, Suspense, lazy } from 'react';
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { AuthContext } from '../context/auth-context';

import instance from "../helpers/Axios.Config";

import Base from "../components/templates/base/index";
import BasePage from "../components/templates/base-page/index";
import PageLoader from "../components/organisms/page-loader/index";

const listofPages = [
  "/login",
  "/notfound"
];

const CustomRoute = ({ isPrivate, location, ...rest }) => {
  const [authState, setAuthState] = useContext(AuthContext);

  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };
  const animationName = "rag-fadeIn";

  useEffect(() => {
    if (!isPrivate)
      return;

    if (authState.authenticated)
      return;

    (async() => {
      try {
        let result = await instance.get("/authorize");

        if (result.status === 200)
          setAuthState({authenticated: true})

    } catch (error) {
        console.log(error);
    }
    })();
  }, [location])

  if (isPrivate === true && !authState.authenticated) {
    return <PageLoader />
  }

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route {...rest} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Route {...rest} />
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
}

export default CustomRoute;

