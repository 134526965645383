import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";

import paths from "./paths";

import CustomRoute from "./custom-route";

//import AcaoPromocionalCategoria from "../components/pages/acaopromocional-categoria/index";


// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const AcessoNegado = lazy(() => import("../components/pages/acessonegado/index"));

const RelatorioInterno = lazy(()=> import("../components/pages/Relatorios/RelatorioInterno/index"));
const RelatorioCliente = lazy(()=> import("../components/pages/Relatorios/RelatorioCliente/index"));
const RelatorioArte = lazy(()=> import("../components/pages/Relatorios/RelatorioArte/index"));

const UsuarioTipoIndex = lazy(() => import("../components/pages/usuario-tipo/index"));
const UsuarioTipoRegister = lazy(() => import("../components/pages/usuario-tipo/register"));
const UsuarioTipoDetails = lazy(() => import("../components/pages/usuario-tipo/details"));
const UsuarioTipoRemove = lazy(() => import("../components/pages/usuario-tipo/remove"));
const UsuarioTipoRoles = lazy(() => import("../components/pages/usuario-tipo/roles"));

const UsuarioIndex = lazy(() => import("../components/pages/usuario/index"));
const UsuarioRegister = lazy(() => import("../components/pages/usuario/register"));
const UsuarioDetails = lazy(() => import("../components/pages/usuario/details"));
const UsuarioRemove = lazy(() => import("../components/pages/usuario/remove"));
const UsuarioRoles = lazy(() => import("../components/pages/usuario/roles"));

const UsuarioMeusDados = lazy(() => import("../components/pages/usuario-meusdados/index"));

const FluxoIndex = lazy(() => import("../components/pages/fluxo/index"));
const FluxoRegister = lazy(() => import("../components/pages/fluxo/register"));
const FluxoEtapas = lazy(() => import("../components/pages/fluxo/etapas"));

const Login = lazy(() => import("../components/pages/Login"));

const UnidadeIndex = lazy(() => import("../components/pages/unidade/index"));
const UnidadeRegister = lazy(() => import("../components/pages/unidade/register"));
const UnidadeRemove = lazy(() => import("../components/pages/unidade/remove"));

const ChamadoTipoIndex = lazy(() => import("../components/pages/chamadotipo/index"));
const ChamadoTipoRegister = lazy(() => import("../components/pages/chamadotipo/register"));
const ChamadoTipoDetails = lazy(() => import("../components/pages/chamadotipo/details"));

const PedidoTipoIndex = lazy(() => import("../components/pages/pedidotipo/index"));
const PedidoTipoRegister = lazy(() => import("../components/pages/pedidotipo/register"));

const PerguntaFrequenteIndex = lazy(() => import("../components/pages/perguntafrequente/index"));
const PerguntaFrequenteRegister = lazy(() => import("../components/pages/perguntafrequente/register"));
const PerguntaFrequenteRemove = lazy(() => import("../components/pages/perguntafrequente/remove"));
const PerguntaFrequenteDetails = lazy(() => import("../components/pages/perguntafrequente/details"));

const ProdutoIndex = lazy(() => import("../components/pages/produto/index"));
const ProdutoRemove = lazy(() => import("../components/pages/produto/remove"));
const ProdutoDetails = lazy(() => import("../components/pages/produto/details"));
const ProdutoRegister = lazy(() => import("../components/pages/produto/register"));
const SearchPreco = lazy(() => import("../components/pages/produtos/Search.Preco"));
const ProdutoCategoriaIndex = lazy(() => import("../components/pages/produto-categoria/index"));
const ProdutoCategoriaRegister = lazy(() => import("../components/pages/produto-categoria/register"));
const ProdutoCategoriaRemove = lazy(() => import("../components/pages/produto-categoria/remove"));
const ProdutoAtividadeIndex = lazy(() => import("../components/pages/produto-atividade/index"));
const ProdutoAtividadeRegister = lazy(() => import("../components/pages/produto-atividade/register"));
const OrdemMarcas = lazy(() => import("../components/pages/produto-categoria/Ordem.Marcas"));
const OrdemCategorias = lazy(() => import("../components/pages/produto-categoria/Ordem.Categorias"));
//const OrdemClasses = lazy(() => import("./components/pages/Produtos/Ordem.Classes"));

const LojaIndex = lazy(() => import("../components/pages/loja/index"));
const LojaRegister = lazy(() => import("../components/pages/loja/register"));
const LojaDetails = lazy(() => import("../components/pages/loja/details"));
const LojaRemove = lazy(() => import("../components/pages/loja/remove"));

const Pendencia = lazy(() => import("../components/pages/pendencia/Pendencia"));
const PendenciaStatus = lazy(() => import("../components/pages/pendencia/Pendecia.Status"));
const SearchArquivoEntrega = lazy(() => import("../components/pages/solicitacao/ArquivoEntrega.Search"));
const ArquivoEntrega = lazy(() => import("../components/pages/solicitacao/ArquivoEntrega"));
const ConsultaSolicitacao = lazy(() => import("../components/pages/solicitacao/Consulta.Solicitacao"));
const AlterarSolicitacao = lazy(() => import("../components/pages/solicitacao/Alterar.Solicitacao"));
const AlterarValoresSolicitacao = lazy(() => import("../components/pages/solicitacao/Alterar.Valores.Solicitacao"));
const TrocarEtapaSolicitacao = lazy(() => import("../components/pages/solicitacao/Trocar.Etapa.Solicitacao"));
const AlterarPendencia = lazy(() => import("../components/pages/solicitacao/Alterar.Pendencia"));

const Fornecedor = lazy(() => import("../components/pages/fornecedor/index"));
const FornecedorRegister = lazy(() => import("../components/pages/fornecedor/register"));
const FornecedorDetails = lazy(() => import("../components/pages/fornecedor/details"));
const FornecedorRemove = lazy(() => import("../components/pages/fornecedor/remove"));

const FormaPagamentoIndex = lazy(() => import("../components/pages/formapagamento/index"));
const FormaPagamentoRegister = lazy(() => import("../components/pages/formapagamento/register"));
const FormaPagamentoRemove = lazy(() => import("../components/pages/formapagamento/remove"));

const MotivoAcaoIndex = lazy(() => import("../components/pages/motivoacao/index"));
const MotivoAcaoRegister = lazy(() => import("../components/pages/motivoacao/register"));
const MotivoAcaoRemove = lazy(() => import("../components/pages/motivoacao/remove"));

const AcaoPromocionalCategoria = lazy(() => import("../components/pages/acaopromocional-categoria/index"));
const AcaoPromocionalCategoriaRegister = lazy(() => import("../components/pages/acaopromocional-categoria/register"));

const AcaoPromocional = lazy(() => import("../components/pages/acaopromocional/index"));
const AcaoPromocionalRegister = lazy(() => import("../components/pages/acaopromocional/register"));

const AtivacaoScanntechMarca = lazy(() => import("../components/pages/ativacaoscanntech-marca/index"));
const AtivacaoScanntechMarcaRegister = lazy(() => import("../components/pages/ativacaoscanntech-marca/register"));

const AtivacaoScanntech = lazy(() => import("../components/pages/ativacaoscanntech/index"));
const AtivacaoScanntechRegister = lazy(() => import("../components/pages/ativacaoscanntech/register"));

const MaterialComunicacaoTipo = lazy(() => import("../components/pages/materialcomunicacao-tipo/index"));
const MaterialComunicacaoTipoRegister = lazy(() => import("../components/pages/materialcomunicacao-tipo/register"));
const MaterialComunicacaoTipoRemove = lazy(() => import("../components/pages/materialcomunicacao-tipo/remove"));

const MaterialComunicacao = lazy(() => import("../components/pages/materialcomunicacao/index"));
const MaterialComunicacaoRegiter = lazy(() => import("../components/pages/materialcomunicacao/register"));
const MaterialComunicacaoDetails = lazy(() => import("../components/pages/materialcomunicacao/details"));
const MaterialComunicacaoRemove = lazy(() => import("../components/pages/materialcomunicacao/remove"));
const MaterialComunicacaoCadastroSolicitacao = lazy(() => import("../components/pages/materialcomunicacao/cadastro-solicitacao"));

const OnOffRedeIndex = lazy(() => import("../components/pages/onoffrede/index"));
const OnOffRedeRegister = lazy(() => import("../components/pages/onoffrede/register"));
const OnOffRedeRemove = lazy(() => import("../components/pages/onoffrede/remove"));

const OnOffMaterialComunicacaoIndex = lazy(() => import("../components/pages/onoffmaterialcomunicacao/index"));
const OnOffMaterialComunicacaoRegister = lazy(() => import("../components/pages/onoffmaterialcomunicacao/register"));
const OnOffMaterialComunicacaoRemove = lazy(() => import("../components/pages/onoffmaterialcomunicacao/remove"));

const LayoutArteRemove = lazy(() => import("../components/pages/layoutarte/remove"));
const LayoutArteProdutosEspecificos = lazy(() => import("../components/pages/layoutarte/produtos/especificos"));
const LayoutArteProdutosObrigatorios = lazy(() => import("../components/pages/layoutarte/produtos/obrigatorios"));

const LayoutArteAcaoIndex = lazy(() => import("../components/pages/layoutarteacao/index"));
const LayoutArteAcaoRegister = lazy(() => import("../components/pages/layoutarteacao/register"));
const LayoutArteAcaoRemove = lazy(() => import("../components/pages/layoutarteacao/remove"));

const LayoutArteMateriaisIndex = lazy(() => import("../components/pages/layoutarte/materiais/index"));
const LayoutArteMateriaisRegister = lazy(() => import("../components/pages/layoutarte/materiais/register"));

const LayoutArteArtesFacilIndex = lazy(() => import("../components/pages/layoutarte/artes-facil/index"));
const LayoutArteArtesFacilRegister = lazy(() => import("../components/pages/layoutarte/artes-facil/register"));

const BancoImagemCategoriaIndex = lazy(() => import("../components/pages/bancoimagem-categoria/index"));
const BancoImagemCategoriaRegister = lazy(() => import("../components/pages/bancoimagem-categoria/register"));
const BancoImagemCategoriaRemove = lazy(() => import("../components/pages/bancoimagem-categoria/remove"));

const BancoImagemIndex = lazy(() => import("../components/pages/bancoimagem/index"));
const BancoImagemRegister = lazy(() => import("../components/pages/bancoimagem/register"));
const BancoImagemDetails = lazy(() => import("../components/pages/bancoimagem/details"));
const BancoImagemRemove = lazy(() => import("../components/pages/bancoimagem/remove"));

const BannerLoginIndex = lazy(() => import("../components/pages/bannerlogin/index"));
const BannerLoginRegister = lazy(() => import("../components/pages/bannerlogin/register"));
const BannerLoginRemove = lazy(() => import("../components/pages/bannerlogin/remove"));
const BannerLoginOrder = lazy(() => import("../components/pages/bannerlogin/order"));

const AvisoIndex = lazy(() => import("../components/pages/aviso/index"));
const AvisoRegister = lazy(() => import("../components/pages/aviso/register"));
const AvisoRemove = lazy(() => import("../components/pages/aviso/remove"));
const AvisoOrder = lazy(() => import("../components/pages/aviso/order"));
const AvisoDetails = lazy(() => import("../components/pages/aviso/details"));

const CanalIndex = lazy(() => import("../components/pages/canal/index"));
const CanalRegister = lazy(() => import("../components/pages/canal/register"));
const CanalRemove = lazy(() => import("../components/pages/canal/remove"));

// const CampanhaIndex = lazy(() => import("../components/pages/campanha/index"));
// const CampanhaRegister = lazy(() => import("../components/pages/campanha/register"));
// const CampanhaRemove = lazy(() => import("../components/pages/campanha/remove"));
// const CampanhaProdutosEspecificos = lazy(() => import("../components/pages/campanha/produtos/especificos"));
// const CampanhaProdutosObrigatorios = lazy(() => import("../components/pages/campanha/produtos/obrigatorios"));

const EtapaLabelIndex = lazy(() => import("../components/pages/etapalabel/index"));
const EtapaLabelRegister = lazy(() => import("../components/pages/etapalabel/register"));
const EtapaLabelRemove = lazy(() => import("../components/pages/etapalabel/remove"));

const RedeIndex = lazy(() => import("../components/pages/rede/index"));
const RedeRegister = lazy(() => import("../components/pages/rede/register"));
const RedeRemove = lazy(() => import("../components/pages/rede/remove"));

const RegionalIndex = lazy(() => import("../components/pages/regional/index"));
const RegionalRegister = lazy(() => import("../components/pages/regional/register"));
const RegionalDetails = lazy(() => import("../components/pages/regional/details"));
const RegionalRemove = lazy(() => import("../components/pages/regional/remove"));
const RegionalProdutosObrigatorios = lazy(() => import("../components/pages/regional/produtos/obrigatorios"));

const RegionalVerbaIndex = lazy(() => import("../components/pages/regional-verba/index"));
const RegionalAprovadorIndex = lazy(() => import("../components/pages/regional-aprovador/index"));
const RegionalAprovadorTradeIndex = lazy(() => import("../components/pages/regional-aprovador-trade/index"));

const ValorDiagramacaoIndex = lazy(() => import("../components/pages/valordiagramacao/index"));
const ValorDiagramacaoRegister = lazy(() => import("../components/pages/valordiagramacao/register"));

const ValorFreteIndex = lazy(() => import("../components/pages/valorfrete/index"));
const ValorFreteRegister = lazy(() => import("../components/pages/valorfrete/register"));

const ValorProducaoIndex = lazy(() => import("../components/pages/valorproducao/index"));
const ValorProducaoRegister = lazy(() => import("../components/pages/valorproducao/register"));
const ValorProducaoRemove = lazy(() => import("../components/pages/valorproducao/remove"));

const SolicitacaoValorRegister = lazy(() => import("../components/pages/solicitacao/valor-register"));
const solicitacaoQtdImpressosRegister = lazy(() => import("../components/pages/solicitacao/QtdImpressos-Register"));
const SolicitacaoIndex = lazy(() => import("../components/pages/solicitacao/index"));

const SolicitacaoAlterar = lazy(() => import("../components/pages/solicitacao/alterar"));
const SolicitacaoAlterarDados = lazy(() => import("../components/pages/solicitacao/alterar-dados"));
const SolicitacaoAlterarEntrega = lazy(() => import("../components/pages/solicitacao/alterar-entrega"));
const SolicitacaoAlterarProdutos = lazy(() => import("../components/pages/solicitacao/alterar-produtos"));
const SolicitacaoVoltarEtapa = lazy(() => import("../components/pages/solicitacao/voltar-etapa"));

const Home = lazy(() => import("../components/pages/home/index"));
const Logout = lazy(() => import("../components/organisms/logout"));
const NotFound = lazy(() => import("../components/pages/NotFound"));

const Routes = ({ location }) => {

  return (
    <Switch location={location}>
      <CustomRoute path="/login" component={waitFor(Login)} />
      <CustomRoute path="/login/:postBackUrl" component={waitFor(Login)} />
      <CustomRoute path="/notfound" component={waitFor(NotFound)} />
      <CustomRoute path="/logout" component={waitFor(Logout)} />

      <CustomRoute isPrivate path="/home" component={waitFor(Home)} />

      <CustomRoute isPrivate exact path="/" component={waitFor(Home)} />

      <CustomRoute isPrivate path="/acessonegado" component={waitFor(AcessoNegado)} />

      {/* <CustomRoute isPrivate exact path={paths.relatorio} component={waitFor(AvisoIndex)} /> */}
      <CustomRoute isPrivate exact path={paths.relatorio + "/RelatorioInterno"} component={waitFor(RelatorioInterno)} />
      <CustomRoute isPrivate exact path={paths.relatorio + "/RelatorioCliente"} component={waitFor(RelatorioCliente)} />
      <CustomRoute isPrivate exact path={paths.relatorio + "/RelatorioArte"} component={waitFor(RelatorioArte)} />

      <CustomRoute isPrivate exact path={paths.aviso} component={waitFor(AvisoIndex)} />
      <CustomRoute isPrivate exact path={paths.aviso + "/register"} component={waitFor(AvisoRegister)} />
      <CustomRoute isPrivate exact path={paths.aviso + "/order"} component={waitFor(AvisoOrder)} />
      <CustomRoute isPrivate exact path={paths.aviso + "/remove"} component={waitFor(AvisoRemove)} />
      <CustomRoute isPrivate exact path={paths.aviso + "/details"} component={waitFor(AvisoDetails)} />

      <CustomRoute isPrivate exact path={paths.bancoImagemCategoria} component={waitFor(BancoImagemCategoriaIndex)} />
      <CustomRoute isPrivate exact path={paths.bancoImagemCategoria + "/register"} component={waitFor(BancoImagemCategoriaRegister)} />
      <CustomRoute isPrivate exact path={paths.bancoImagemCategoria + "/remove"} component={waitFor(BancoImagemCategoriaRemove)} />

      <CustomRoute isPrivate exact path={paths.bancoImagem} component={waitFor(BancoImagemIndex)} />
      <CustomRoute isPrivate exact path={paths.bancoImagem + "/register"} component={waitFor(BancoImagemRegister)} />
      <CustomRoute isPrivate exact path={paths.bancoImagem + "/details"} component={waitFor(BancoImagemDetails)} />
      <CustomRoute isPrivate exact path={paths.bancoImagem + "/remove"} component={waitFor(BancoImagemRemove)} />

      <CustomRoute isPrivate exact path={paths.bannerLogin} component={waitFor(BannerLoginIndex)} />
      <CustomRoute isPrivate exact path={paths.bannerLogin + "/register"} component={waitFor(BannerLoginRegister)} />
      <CustomRoute isPrivate exact path={paths.bannerLogin + "/order"} component={waitFor(BannerLoginOrder)} />
      <CustomRoute isPrivate exact path={paths.bannerLogin + "/remove"} component={waitFor(BannerLoginRemove)} />

      <CustomRoute isPrivate exact path={paths.etapalabel} component={waitFor(EtapaLabelIndex)} />
      <CustomRoute isPrivate exact path={paths.etapalabel + "/add"} component={waitFor(EtapaLabelRegister)} />
      <CustomRoute isPrivate exact path={paths.etapalabel + "/edit/:id"} component={waitFor(EtapaLabelRegister)} />
      <CustomRoute isPrivate exact path={paths.etapalabel + "/remove/:id"} component={waitFor(EtapaLabelRemove)} />

      <CustomRoute isPrivate exact path={paths.chamadotipo} component={waitFor(ChamadoTipoIndex)} />
      <CustomRoute isPrivate exact path={paths.chamadotipo + "/add"} component={waitFor(ChamadoTipoRegister)} />
      <CustomRoute isPrivate exact path={paths.chamadotipo + "/edit/:id"} component={waitFor(ChamadoTipoRegister)} />
      <CustomRoute isPrivate exact path={paths.chamadotipo + "/details/:id"} component={waitFor(ChamadoTipoDetails)} />

      <CustomRoute isPrivate exact path={paths.pedidotipo} component={waitFor(PedidoTipoIndex)} />
      <CustomRoute isPrivate exact path={paths.pedidotipo + "/add"} component={waitFor(PedidoTipoRegister)} />
      <CustomRoute isPrivate exact path={paths.pedidotipo + "/edit/:id"} component={waitFor(PedidoTipoRegister)} />

      <CustomRoute isPrivate exact path={paths.perguntafrequente} component={waitFor(PerguntaFrequenteIndex)} />
      <CustomRoute isPrivate exact path={paths.perguntafrequente + "/add"} component={waitFor(PerguntaFrequenteRegister)} />
      <CustomRoute isPrivate exact path={paths.perguntafrequente + "/edit/:id"} component={waitFor(PerguntaFrequenteRegister)} />
      <CustomRoute isPrivate exact path={paths.perguntafrequente + "/remove/:id"} component={waitFor(PerguntaFrequenteRemove)} />
      <CustomRoute isPrivate exact path={paths.perguntafrequente + "/details/:id"} component={waitFor(PerguntaFrequenteDetails)} />

      <CustomRoute isPrivate exact path={paths.produto} component={waitFor(ProdutoIndex)} />
      <CustomRoute isPrivate exact path={paths.produto + "/register"} component={waitFor(ProdutoRegister)} />
      <CustomRoute isPrivate exact path={paths.produto + "/details"} component={waitFor(ProdutoDetails)} />
      <CustomRoute isPrivate exact path={paths.produto + "/remove"} component={waitFor(ProdutoRemove)} />

      <CustomRoute isPrivate exact path={paths.produtoatividade} component={waitFor(ProdutoAtividadeIndex)} />
      <CustomRoute isPrivate exact path={paths.produtoatividade + "/add"} component={waitFor(ProdutoAtividadeRegister)} />
      <CustomRoute isPrivate exact path={paths.produtoatividade + "/edit/:id"} component={waitFor(ProdutoAtividadeRegister)} />

      <CustomRoute isPrivate exact path={paths.produtoCategoria} component={waitFor(ProdutoCategoriaIndex)} />
      <CustomRoute isPrivate exact path={paths.produtoCategoria + "/register"} component={waitFor(ProdutoCategoriaRegister)} />
      <CustomRoute isPrivate exact path={paths.produtoCategoria + "/remove"} component={waitFor(ProdutoCategoriaRemove)} />

      <CustomRoute isPrivate exact path={paths.unidade} component={waitFor(UnidadeIndex)} />
      <CustomRoute isPrivate exact path={paths.unidade + "/register"} component={waitFor(UnidadeRegister)} />
      <CustomRoute isPrivate exact path={paths.unidade + "/remove"} component={waitFor(UnidadeRemove)} />

      <CustomRoute isPrivate exact path={paths.usuarioTipo} component={waitFor(UsuarioTipoIndex)} />
      <CustomRoute isPrivate exact path={paths.usuarioTipo + "/register"} component={waitFor(UsuarioTipoRegister)} />
      <CustomRoute isPrivate exact path={paths.usuarioTipo + "/details"} component={waitFor(UsuarioTipoDetails)} />
      <CustomRoute isPrivate exact path={paths.usuarioTipo + "/remove"} component={waitFor(UsuarioTipoRemove)} />
      <CustomRoute isPrivate exact path={paths.usuarioTipo + "/roles"} component={waitFor(UsuarioTipoRoles)} />

      <CustomRoute isPrivate exact path={paths.usuario} component={waitFor(UsuarioIndex)} />
      <CustomRoute isPrivate exact path={paths.usuario + "/register"} component={waitFor(UsuarioRegister)} />
      <CustomRoute isPrivate exact path={paths.usuario + "/details"} component={waitFor(UsuarioDetails)} />
      <CustomRoute isPrivate exact path={paths.usuario + "/remove"} component={waitFor(UsuarioRemove)} />
      <CustomRoute isPrivate exact path={paths.usuario + "/roles"} component={waitFor(UsuarioRoles)} />


      <CustomRoute isPrivate exact path={paths.usuarioMeusDados} component={waitFor(UsuarioMeusDados)} />

      <CustomRoute isPrivate exact path={paths.fluxo} component={waitFor(FluxoIndex)} />
      <CustomRoute isPrivate exact path={paths.fluxo + "/register"} component={waitFor(FluxoRegister)} />
      <CustomRoute isPrivate exact path={paths.fluxo + "/etapas"} component={waitFor(FluxoEtapas)} />

      <CustomRoute isPrivate exact path={paths.formaPagamento} component={waitFor(FormaPagamentoIndex)} />
      <CustomRoute isPrivate exact path={paths.formaPagamento + "/register"} component={waitFor(FormaPagamentoRegister)} />
      <CustomRoute isPrivate exact path={paths.formaPagamento + "/remove"} component={waitFor(FormaPagamentoRemove)} />

      <CustomRoute isPrivate exact path={paths.motivoAcao} component={waitFor(MotivoAcaoIndex)} />
      <CustomRoute isPrivate exact path={paths.motivoAcao + "/register"} component={waitFor(MotivoAcaoRegister)} />
      <CustomRoute isPrivate exact path={paths.motivoAcao + "/remove"} component={waitFor(MotivoAcaoRemove)} />

      <CustomRoute isPrivate exact path={paths.acaoPromocionalCategoria} > <AcaoPromocionalCategoria /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.acaoPromocionalCategoria + "/register"} ><AcaoPromocionalCategoriaRegister /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.acaoPromocionalCategoria + "/register/:id"} ><AcaoPromocionalCategoriaRegister /></CustomRoute>

      <CustomRoute isPrivate exact path={paths.acaoPromocional} > <AcaoPromocional /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.acaoPromocional + "/add"} ><AcaoPromocionalRegister /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.acaoPromocional + "/update/:id"} ><AcaoPromocionalRegister /></CustomRoute>

      <CustomRoute isPrivate exact path={paths.ativacaoScanntechMarca} > <AtivacaoScanntechMarca /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.ativacaoScanntechMarca + "/register"} ><AtivacaoScanntechMarcaRegister /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.ativacaoScanntechMarca + "/register/:id"} ><AtivacaoScanntechMarcaRegister /></CustomRoute>

      <CustomRoute isPrivate exact path={paths.ativacaoScanntech} > <AtivacaoScanntech /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.ativacaoScanntech + "/add"} ><AtivacaoScanntechRegister /></CustomRoute>
      <CustomRoute isPrivate exact path={paths.ativacaoScanntech + "/update/:id"} ><AtivacaoScanntechRegister /></CustomRoute>

      <CustomRoute isPrivate exact path={paths.layoutArte + "/remove/:id"} component={waitFor(LayoutArteRemove)} />
      <CustomRoute isPrivate exact path={paths.layoutArte + "/produtos-especificos/:id"} component={waitFor(LayoutArteProdutosEspecificos)} />
      <CustomRoute isPrivate exact path={paths.layoutArte + "/produtos-obrigatorios/:id"} component={waitFor(LayoutArteProdutosObrigatorios)} />

      <CustomRoute isPrivate exact path={paths.layoutArteAcao} component={waitFor(LayoutArteAcaoIndex)} />
      <CustomRoute isPrivate exact path={paths.layoutArteAcao + "/add"} component={waitFor(LayoutArteAcaoRegister)} />
      <CustomRoute isPrivate exact path={paths.layoutArteAcao + "/update/:id"} component={waitFor(LayoutArteAcaoRegister)} />

      <CustomRoute isPrivate exact path={paths.layoutArte + "/materiais"} component={waitFor(LayoutArteMateriaisIndex)} />
      <CustomRoute isPrivate exact path={paths.layoutArte + "/materiais/register/:id?"} component={waitFor(LayoutArteMateriaisRegister)} />

      <CustomRoute isPrivate exact path={paths.layoutArte + "/artes-facil"} component={waitFor(LayoutArteArtesFacilIndex)} />
      <CustomRoute isPrivate exact path={paths.layoutArte + "/artes-facil/register/:id?"} component={waitFor(LayoutArteArtesFacilRegister)} />

      <CustomRoute isPrivate path="/search-preco" component={waitFor(SearchPreco)} />
      <CustomRoute isPrivate path="/ordem-marcas" component={waitFor(OrdemMarcas)} />
      <CustomRoute isPrivate path="/ordem-categorias" component={waitFor(OrdemCategorias)} />

      <CustomRoute isPrivate exact path={paths.loja} component={waitFor(LojaIndex)} />
      <CustomRoute isPrivate exact path={paths.loja + "/edit/:id"} component={waitFor(LojaRegister)} />
      <CustomRoute isPrivate exact path={paths.loja + "/add"} component={waitFor(LojaRegister)} />
      <CustomRoute isPrivate exact path={paths.loja + "/details/:id"} component={waitFor(LojaDetails)} />
      <CustomRoute isPrivate exact path={paths.loja + "/remove/:id"} component={waitFor(LojaRemove)} />

      <CustomRoute isPrivate exact path={paths.solicitacao} component={waitFor(SolicitacaoIndex)} />
      <CustomRoute isPrivate exact path={`${paths.solicitacaoAlterar}/:solicitacaoId`} component={waitFor(SolicitacaoAlterar)} />
      <CustomRoute isPrivate exact path={`${paths.solicitacaoAlterarDados}/:solicitacaoId`} component={waitFor(SolicitacaoAlterarDados)} />
      <CustomRoute isPrivate exact path={`${paths.solicitacaoAlterarEntrega}/:solicitacaoId`} component={waitFor(SolicitacaoAlterarEntrega)} />
      <CustomRoute isPrivate exact path={`${paths.solicitacaoAlterarProdutos}/:solicitacaoId`} component={waitFor(SolicitacaoAlterarProdutos)} />
      <CustomRoute isPrivate exact path={`${paths.solicitacaoVoltarEtapa}`} component={waitFor(SolicitacaoVoltarEtapa)} />
      {/* <CustomRoute isPrivate exact path={`${paths.solicitacaoValores}/:id`} component={waitFor(SolicitacaoValorRegister)} />
                  <CustomRoute isPrivate exact path={`${paths.solicitacaoValores}`} component={waitFor(SolicitacaoValorRegister)} />
                  <CustomRoute isPrivate exact path={`${paths.solicitacaoVoltarEtapa}`} component={waitFor(SolicitacaoVoltarEtapa)} /> */}
      <CustomRoute isPrivate exact path="/alterar-pendencia" component={waitFor(AlterarPendencia)} />
      <CustomRoute isPrivate exact path="/alterar-pendencia/:solicitacaoId" component={waitFor(AlterarPendencia)} />

      <CustomRoute isPrivate path="/pendencia-status/:solicitacaoId" component={waitFor(PendenciaStatus)} />
      <CustomRoute isPrivate path="/search-arquivo-entrega-loja-loja" component={waitFor(SearchArquivoEntrega)} />
      <CustomRoute isPrivate path="/arquivo-entrega-loja-loja/:solicitacaoId" component={waitFor(ArquivoEntrega)} />
      <CustomRoute isPrivate path="/consulta-solicitacao" component={waitFor(ConsultaSolicitacao)} />
      <CustomRoute isPrivate path="/alterar-solicitacao/:solicitacaoId" component={waitFor(AlterarSolicitacao)} />
      <CustomRoute isPrivate path="/alterar-valores-solicitacao" component={waitFor(AlterarValoresSolicitacao)} />
      <CustomRoute isPrivate path="/trocar-etapa-solicitacao" component={waitFor(TrocarEtapaSolicitacao)} />
      <CustomRoute isPrivate path="/alterar-pendencia" component={waitFor(AlterarPendencia)} />

      <CustomRoute isPrivate exact path={paths.fornecedor} component={waitFor(Fornecedor)} />
      <CustomRoute isPrivate exact path={paths.fornecedor + "/add"} component={waitFor(FornecedorRegister)} />
      <CustomRoute isPrivate exact path={paths.fornecedor + "/edit/:id"} component={waitFor(FornecedorRegister)} />
      <CustomRoute isPrivate exact path={paths.fornecedor + "/details/:id"} component={waitFor(FornecedorDetails)} />
      <CustomRoute isPrivate exact path={paths.fornecedor + "/remove/:id"} component={waitFor(FornecedorRemove)} />

      <CustomRoute isPrivate exact path={paths.materialcomunicacaoTipo} component={waitFor(MaterialComunicacaoTipo)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacaoTipo + "/add"} component={waitFor(MaterialComunicacaoTipoRegister)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacaoTipo + "/edit/:id"} component={waitFor(MaterialComunicacaoTipoRegister)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacaoTipo + "/remove/:id"} component={waitFor(MaterialComunicacaoTipoRemove)} />

      <CustomRoute isPrivate exact path={paths.materialcomunicacao} component={waitFor(MaterialComunicacao)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacao + "/add"} component={waitFor(MaterialComunicacaoRegiter)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacao + "/edit/:id"} component={waitFor(MaterialComunicacaoRegiter)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacao + "/details/:id"} component={waitFor(MaterialComunicacaoDetails)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacao + "/remove/:id"} component={waitFor(MaterialComunicacaoRemove)} />
      <CustomRoute isPrivate exact path={paths.materialcomunicacao + "/cadastro-solicitacao"} component={waitFor(MaterialComunicacaoCadastroSolicitacao)} />

      <CustomRoute isPrivate exact path={paths.onoffrede} component={waitFor(OnOffRedeIndex)} />
      <CustomRoute isPrivate exact path={paths.onoffrede + "/register"} component={waitFor(OnOffRedeRegister)} />
      <CustomRoute isPrivate exact path={paths.onoffrede + "/remove/:id"} component={waitFor(OnOffRedeRemove)} />

      <CustomRoute isPrivate exact path={paths.onoffmaterialcomunicacao} component={waitFor(OnOffMaterialComunicacaoIndex)} />
      <CustomRoute isPrivate exact path={paths.onoffmaterialcomunicacao + "/register"} component={waitFor(OnOffMaterialComunicacaoRegister)} />
      <CustomRoute isPrivate exact path={paths.onoffmaterialcomunicacao + "/remove/:id"} component={waitFor(OnOffMaterialComunicacaoRemove)} />

      <CustomRoute isPrivate exact path={paths.canal} component={waitFor(CanalIndex)} />
      <CustomRoute isPrivate exact path={paths.canal + "/add"} component={waitFor(CanalRegister)} />
      <CustomRoute isPrivate exact path={paths.canal + "/edit/:id"} component={waitFor(CanalRegister)} />
      <CustomRoute isPrivate exact path={paths.canal + "/remove/:id"} component={waitFor(CanalRemove)} />

      {/* <CustomRoute isPrivate exact path={paths.campanha} component={waitFor(CampanhaIndex)} />
      <CustomRoute isPrivate exact path={paths.campanha + "/add"} component={waitFor(CampanhaRegister)} />
      <CustomRoute isPrivate exact path={paths.campanha + "/edit/:id"} component={waitFor(CampanhaRegister)} />
      <CustomRoute isPrivate exact path={paths.campanha + "/remove/:id"} component={waitFor(CampanhaRemove)} />
      <CustomRoute isPrivate exact path={paths.campanha + "/produtos-especificos/:id"} component={waitFor(CampanhaProdutosEspecificos)} />
      <CustomRoute isPrivate exact path={paths.campanha + "/produtos-obrigatorios/:id"} component={waitFor(CampanhaProdutosObrigatorios)} /> */}

      <CustomRoute isPrivate exact path={paths.rede} component={waitFor(RedeIndex)} />
      <CustomRoute isPrivate exact path={paths.rede + "/add"} component={waitFor(RedeRegister)} />
      <CustomRoute isPrivate exact path={paths.rede + "/edit/:id"} component={waitFor(RedeRegister)} />
      <CustomRoute isPrivate exact path={paths.rede + "/remove/:id"} component={waitFor(RedeRemove)} />

      <CustomRoute isPrivate exact path={paths.regional} component={waitFor(RegionalIndex)} />
      <CustomRoute isPrivate exact path={paths.regional + "/add"} component={waitFor(RegionalRegister)} />
      <CustomRoute isPrivate exact path={paths.regional + "/edit/:id"} component={waitFor(RegionalRegister)} />
      <CustomRoute isPrivate exact path={paths.regional + "/details/:id"} component={waitFor(RegionalDetails)} />
      <CustomRoute isPrivate exact path={paths.regional + "/remove/:id"} component={waitFor(RegionalRemove)} />
      <CustomRoute isPrivate exact path={paths.regional + "/produtos-obrigatorios/:id"} component={waitFor(RegionalProdutosObrigatorios)} />

      <CustomRoute isPrivate exact path={paths.regionalVerba} component={waitFor(RegionalVerbaIndex)} />
      <CustomRoute isPrivate exact path={paths.regionalAprovador} component={waitFor(RegionalAprovadorIndex)} />
      <CustomRoute isPrivate exact path={paths.regionalAprovadorTrade} component={waitFor(RegionalAprovadorTradeIndex)} />

      <CustomRoute isPrivate exact path={paths.valorDiagramacao} component={waitFor(ValorDiagramacaoIndex)} />
      <CustomRoute isPrivate exact path={paths.valorDiagramacao + "/add"} component={waitFor(ValorDiagramacaoRegister)} />
      <CustomRoute isPrivate exact path={paths.valorDiagramacao + "/edit/:id"} component={waitFor(ValorDiagramacaoRegister)} />

      <CustomRoute isPrivate exact path={paths.valorFrete} component={waitFor(ValorFreteIndex)} />
      {/* <CustomRoute isPrivate exact path={paths.valorFrete + "/add"} component={waitFor(ValorFreteRegister)} /> */}
      <CustomRoute isPrivate exact path={paths.valorFrete + "/edit/:id"} component={waitFor(ValorFreteRegister)} />

      <CustomRoute isPrivate exact path={paths.valorProducao} component={waitFor(ValorProducaoIndex)} />
      <CustomRoute isPrivate exact path={paths.valorProducao + "/add"} component={waitFor(ValorProducaoRegister)} />
      <CustomRoute isPrivate exact path={paths.valorProducao + "/edit/:id"} component={waitFor(ValorProducaoRegister)} />
      <CustomRoute isPrivate exact path={paths.valorProducao + "/remove/:id"} component={waitFor(ValorProducaoRemove)} />

      <CustomRoute isPrivate exact path={paths.solicitacao + "/valores/:id"} component={waitFor(SolicitacaoValorRegister)} />
      <CustomRoute isPrivate exact path={paths.solicitacao + "/valores"} component={waitFor(SolicitacaoValorRegister)} />
      <CustomRoute isPrivate exact path={paths.solicitacao + "/qtd-impressos/:id"} component={waitFor(solicitacaoQtdImpressosRegister)} />
      <CustomRoute isPrivate exact path={paths.solicitacao + "/qtd-impressos"} component={waitFor(solicitacaoQtdImpressosRegister)} />
      <CustomRoute isPrivate exact path={paths.solicitacao} component={waitFor(SolicitacaoIndex)} />

      
      <Redirect to="/notfound" />
      {/* <Redirect to="/login/:postBackUrl" /> */}
      {/* Sempre deixar por ultimo */}
    </Switch>

  );
}

export default withRouter(Routes);
